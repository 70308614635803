<template>
    <div class="legenda">
        <h3 class="mb-4">Legenda argomenti</h3>
        <ul class="lista-legenda">
            <template v-for="categoria in categorie" :key="categoria.id">
                <li v-for="argomento in categoria.argomenti" :key="argomento.id">
                    <template v-if="argomento.icona_file">
                        <img class="immagine-icona" :src="argomento.icona_file" alt=""> {{ argomento.nome }}
                    </template>
                    <template v-else-if="argomento.icona">
                        <div class="box-icona"><i :class="argomento.icona" class="icona"></i></div> {{ argomento.nome }}
                    </template>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState([
            'categorie'
        ]),
    },
}
</script>