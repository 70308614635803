import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/segnalazioni',
    name: 'Segnalazioni',
    component: () => import(/* webpackChunkName: "segnalazioni" */ '../views/Segnalazioni.vue')
  },
  {
    path: '/aiuto',
    name: 'Aiuto',
    component: () => import(/* webpackChunkName: "aiuto" */ '../views/Aiuto.vue')
  },
  {
    path: '/presentazione',
    name: 'Presentazione',
    component: () => import(/* webpackChunkName: "presentazione" */ '../views/Presentazione.vue')
  },
  {
    path: '/contatti',
    name: 'Contatti',
    component: () => import(/* webpackChunkName: "contatti" */ '../views/Contatti.vue')
  },
  {
    path: '/segnalazioni/:id',
    name: 'SegnalazioniDetails',
    component: () => import(/* webpackChunkName: "segnalazioni" */ '../views/SegnalazioniDetails.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
