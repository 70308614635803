<template>
    <header id="header">
        <div class="logo">
            <router-link to="/">
                <img :src="ente.logo" alt="">
                <h1>{{ ente.nome }}</h1>
            </router-link>
        </div>
        <nav class="desktop">
            <ul>
                <li><router-link to="/segnalazioni">Lista segnalazioni</router-link></li>
                <li><router-link to="/aiuto">Aiuto</router-link></li>
                <li><router-link to="/presentazione">Presentazione</router-link></li>
                <li><router-link to="/contatti">Contatti</router-link></li>
                <template v-if="ente.link_esterno_privacy">
                    <li><a :href="ente.link_esterno_privacy" target="_blank">Privacy</a></li>
                </template>
            </ul>
        </nav>
        <nav class="mobile">
            <button @click="toggleMenu()" aria-label="Apri Menu">
                <i class="fas fa-bars"></i>
            </button>
            <Transition name="slide-fade">
                <ul v-show="mobileMenu">
                    <button @click="toggleMenu()" aria-label="Chiudi menu">
                        <i class="fas fa-times"></i>
                    </button>
                    <li><router-link to="/segnalazioni" @click="toggleMenu()">Lista segnalazioni</router-link></li>
                    <li><router-link to="/aiuto" @click="toggleMenu()">Aiuto</router-link></li>
                    <li><router-link to="/presentazione" @click="toggleMenu()">Presentazione</router-link></li>
                    <li><router-link to="/contatti" @click="toggleMenu()">Contatti</router-link></li>
                    <template v-if="ente.link_esterno_privacy">
                        <li><a :href="ente.link_esterno_privacy" target="_blank">Privacy</a></li>
                    </template>
                </ul>
            </Transition>
        </nav>
    </header>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            mobileMenu: false
        }
    },
    computed: {
        ...mapState([
            'ente',
        ]),
    },
    methods: {
        toggleMenu() {
            this.mobileMenu = !this.mobileMenu
        }
    }
}
</script>