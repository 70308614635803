import { createStore } from "vuex";
import axios from 'axios';
import configApi from "../../public/config.api.json";

export default new createStore({
    state: {
        segnalazioni:[],
        categorie:[],
        ente:[],
        configApi: configApi,
        welcomeModal: true,
        // Variabili per check richieste
        refCount: 0,
        isLoading: false
    },
    mutations: {
        setEnte(state, data) {
            state.ente = data;
        },
        setSegnalazioni(state, data) {
            state.segnalazioni = data;
        },
        setCategorie(state, data) {
            state.categorie = data;
        },
        toggleWelcomeModal(state) {
            state.welcomeModal = !state.welcomeModal
        },
        // Controllo stato richieste per spinner
        loading(state, isLoading) {
                // console.log({isLoading})
                if (isLoading) {
                state.refCount++;
                state.isLoading = true;
                } else if (state.refCount > 0) {
                state.refCount--;
                state.isLoading = (state.refCount > 0);
                }
            }
    },
    actions: {
        getEnte({ commit }) {
            axios.get(configApi.baseUrl + "/ente")
            .then(response => {
                commit('setEnte', response.data)
            })
        },
        getSegnalazioni({ commit }) {
            axios.get(configApi.baseUrl + "/segnalazioni")
            .then(response => {
                commit('setSegnalazioni', response.data)
            })
        },
        getCategorie({ commit }) {
            axios.get(configApi.baseUrl + "/categorie")
            .then(response => {
                commit('setCategorie', response.data)
            })
        },
        
    },
    getters: {
        getSegnalazioneById: (state) => (id) => {
            return state.segnalazioni.find(segnalazione => segnalazione.id === id)
        }
    },
});