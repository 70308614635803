<template>
    <div class="modale" v-show="welcomeModal" @click.self="toggleWelcomeModal">
        <div class="box-invio-segnalazione">
            <button id="closeBoxSegnal" aria-label="Chiudi box segnalazione" @click="toggleWelcomeModal"><img src="../assets/img/icone/close.svg" alt=""></button>
            <div class="testata-modale-welcome">
                <div class="sx">
                    <img :src="ente.logo" class="logo-ente" alt="">
                    <h2>{{ente.nome}}</h2>
                </div>
                <div class="dx">
                    <img class="logo-eurp" src="../assets/img/logo_eurp.svg" alt="">
                </div>
            </div>
            <span v-html="ente.messaggio_benvenuto"></span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    // props:['welcomeModalActive'],
    // setup(props, {emit}) {
    //     const close = () => {
    //         emit("close");
    //     };
    //     return { close };
    // }
    computed: {
        ...mapState([
            'welcomeModal',
            'ente'
        ]),
    },    
    methods: {
        toggleWelcomeModal () {
            this.$store.commit('toggleWelcomeModal')
        }
    }
}
</script>
