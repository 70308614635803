<template>
    <h3 class="mb-3">Filtra segnalazioni</h3>
    <div>
        <div class="form-check">
            <input class="form-check-input" type="radio" value="filtraUltime" v-model="scelta" id="filtraUltime" checked>
            <label class="form-check-label" for="filtraUltime">
                Ultime inserite
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" value="filtraAperte" v-model="scelta" id="filtraAperte">
            <label class="form-check-label" for="filtraAperte">
                Aperte
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" value="filtraChiuse" v-model="scelta" id="filtraChiuse">
            <label class="form-check-label" for="filtraChiuse">
                Chiuse
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" value="filtraTutte" v-model="scelta" id="filtraTutte">
            <label class="form-check-label" for="filtraTutte">
                Tutte
            </label>
        </div>
    </div>
    <p>{{ scelta }}</p>
</template>

<script>
export default {
    data() {
        return {
            scelta: 'filtraUltime',
        }
    },
}
</script>

<style>

</style>