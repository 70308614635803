<template>
  <div class="loading-screen" v-if="isLoading">
    <div class="box">
      <img src="./assets/img/logo_eurp_bianco.svg" alt="">
      <div>Caricamento dati...</div>
    </div>
    <div class="sk-chase">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
    </div>
  </div>
  <div id="modaleZone"></div>
  <Header />
  <router-view/>
</template>

<script>
import Header from "./components/Header";
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  components: { Header },
  computed: {
    ...mapState(['isLoading', 'refCount', 'ente'])
  },
  created() {
    axios.interceptors.request.use((config) => {
      this.$store.commit('loading', true);
      return config;
    }, (error) => {
      this.$store.commit('loading', false);
      return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      this.$store.commit('loading', false);
      return response;
    }, (error) => {
      this.$store.commit('loading', false);
      return Promise.reject(error);
    });
  },
  methods: {
    async fetchEnte() {
      this.$store.dispatch("getEnte");
    },
    async fetchSegnalazioni() {
      this.$store.dispatch("getSegnalazioni");
    },
    async fetchCategorie() {
      this.$store.dispatch("getCategorie");
    },
  },
  mounted() {
    this.fetchEnte();
    this.fetchSegnalazioni();
    this.fetchCategorie();
    // document.title = "eURP - " + this.ente.nome
    document.title = "eURP"
  }
}
</script>